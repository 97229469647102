import Vue from 'vue'
import Vuex from 'vuex'
import actions from "./actions/index";
import mutations from "./mutations";
// import config from "../configuration"

Vue.use(Vuex)

export default new Vuex.Store({
  actions,
  mutations,
  state: {
    url: "ivlev.loymax.tech",
    version: "v1.2",
    authToken: null,
    registration_actions: [],
    questions_data: [],
    _modals: [],
    user: {
      phoneNumber: null,
      code: null,
    },
    // конфигурация/настройка
    config: {
      // вместо пароля отправлять код регистрации, скрыв шаг ввода пароля
      // false - нет
      // true - да
      password_as_confirmation_code: true,
      url_redirect: "https://ivlev-chef.ru/", // url для перенаправления после регистрации
      redirect_timer: 3000, // время после которого просиходит редирект в мс
      finish_text: "Поздравляем!\n Вы успешно прошли регистрацию.", // текст в конце регистрации
      code_timer: 120, // время таймера отправки кода в секундах, 120
      contact_center: "78005050909", // номер контактного центра
      // API-ключ DaData
      dadata_token: "b8aa75aaaa42c18ca78fd763868cd0edbad34b56",
      // поле ввода E-mail
      // 0 - скрыть поле ввода
      // 1 - необязательное поле
      // 2 - обязательное поле
      email: 2,
      // --------------------------------
      // возможность пропустить подтверждение email
      // 0 - нельзя пропустить
      // 1 - можно пропустить
      email_confirm: 1,
      // --------------------------------
      // поле ввода физической карты
      // 0 - скрыть поле ввода
      // 1 - необязательное поле
      // 2 - обязательное поле
      card: 1,
      // --------------------------------
      // Выпустить виртуальную карту
      // 0 - не выпускаем в любом случае
      // 1 - выпускаем в любом случае
      // 2 - если пользователь указал, что есть карта и ввел ее - не выпускаем
      //     если нет карты или не ввел карту, то выпускаем
      virtual_card: 2,
      // --------------------------------
      // массив с localName видимых вопросов в анкете, перечислять через запятую
      visible_questions: [
        "LastName",
        "FirstName",
        "PatronymicName",
        "Birthday",
        "Sex",
        "Address",
      ],
      // ---------------------------------
      // массив с localName обязательных вопросов в анкете
      required_questions: ["LastName", "FirstName", "Birthday", "Sex"],
      // скрытый вопрос для подтверждения что адрес введен
      // aifi_question: {
      //   fixedAnswerIds: null,
      //   questionGroupId: 2, // id группы вопросов
      //   questionId: 43, // id вопроса
      //   tag: "0",
      //   value: null,
      // }
    },
    codes: {
      registration: {
        BEGIN_REGISTRATION_CODE: 1,
        PHONE_CONFIRM_CODE: 2,
        PHONE_CHANGE_CODE: 3,
        EMAIL_CONFIRM_CODE: 5,
        PASSWORD_CHANGE_CODE: 6,
        QUESTIONS_CODE: 7,
        FINISH_CODE: 8,
        CONTINUE_REGISTRATION: 9,
        CARD_ALREADY_REGISTERED: 10,  // карта уже зарегистрирована
      },
      bonus_cards: {
        CHOICE_CODE: 1,
        AUTH_CONFIRM_CODE: 2,
        CARD_SET_CODE: 3,
        QUESTIONS_CODE: 4,
        EMAIL_CHANGE: 5,
        FINISH_CODE: 6,
      },
    }
  }
})
