<template>
  <div id="app">
    <img class="form-logo" :src="require('@/assets/images/ivlev_logo.png')" alt="Описание картинки" />
    <router-view :key="$route.fullPath"></router-view>
    <Transition name="bounce">
      <ModalComponent />
    </Transition>
  </div>
</template>

<script>
import ModalComponent from "@/components/modals/index.vue";

export default {
  components: { ModalComponent }
};
</script>

<style lang="stylus">
@import "assets/styles/index.styl"
#app {
  background-image url("./assets/images/bg.jpg")
  background-repeat no-repeat
  background-size cover
  background-position center bottom
}

.form-logo {
  width: 425px;
  margin: 0 auto;
  background-image url("./assets/images/moh-header.png")
  background-size contain
  background-repeat no-repeat
}
</style>